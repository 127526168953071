import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'App';
import { dark } from 'theme';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LoadStripe from 'LoadStripe';
import "typeface-open-sans";
import * as Sentry from '@sentry/react';
import { Crisp, ChatboxPosition, ChatboxColors } from 'crisp-sdk-web';
import { store } from 'store/store';
const darkState = localStorage.getItem('darkState') || 'dark';
const theme = darkState === 'dark' ? dark : dark;
import PublicScoreSheet from 'components/producer/event/rubricScoring/PublicScoreSheet';
import ScoreSheet from 'components/producer/event/rubricScoring/ScoreSheet';
import { QueryClient, QueryClientProvider } from 'react-query';

// Lazy load components
const Splash = React.lazy(() => import("components/Splash"));
const Login = React.lazy(() => import("components/user/Login"));
const AddEditProgramContainer = React.lazy(() => import("components/program/AddEditProgramContainer"));
const AddEditLocationContainer = React.lazy(() => import("components/program/location/AddEditLocationContainer"));
const AddEditProducerContainer = React.lazy(() => import("components/producer/AddEditProducerContainer"));
const ScrapeUSASF = React.lazy(() => import("components/program/ScrapeUSASF"));
const Account = React.lazy(() => import("components/user/Account"));
const AddEditBrand = React.lazy(() => import("components/producer/brand/AddEditBrand"));
const AddEditEvent = React.lazy(() => import("components/producer/event/AddEditEvent"));
const AddEditTeamContainer = React.lazy(() => import("components/program/team/AddEditTeamContainer"));
const Athlete = React.lazy(() => import("components/program/athlete/Athlete"));
const Guardian = React.lazy(() => import("components/program/athlete/guardian/Guardian"));
const CoachContainer = React.lazy(() => import("components/program/coach/CoachContainer"));
const AddEditAthlete = React.lazy(() => import("components/program/athlete/AddEditAthlete"));
const AddAthleteList = React.lazy(() => import("components/program/athlete/AddAthleteList"));
const AddEditGuardian = React.lazy(() => import("components/program/athlete/guardian/AddEditGuardian"));
const AddEditCoach = React.lazy(() => import("components/program/coach/AddEditCoach"));
const AddEditPersonnel = React.lazy(() => import("components/program/personnel/AddEditPersonnel"));
const AddEditUser = React.lazy(() => import("components/user/AddEditUser"));
const Personnel = React.lazy(() => import("components/program/personnel/Personnel"));
const ProgramContainer = React.lazy(() => import("components/program/ProgramContainer"));
const LocationContainer = React.lazy(() => import("components/program/location/LocationContainer"));
const TeamContainer = React.lazy(() => import('components/program/team/TeamContainer'));
const ProducerContainer = React.lazy(() => import('components/producer/ProducerContainer'));
const BrandContainer = React.lazy(() => import('components/producer/brand/BrandContainer'));
const EventContainer = React.lazy(() => import('components/producer/event/EventContainer'));
const AddEditJudge = React.lazy(() => import("components/producer/judge/AddEditJudge"));
const Judge = React.lazy(() => import("components/producer/judge/Judge"));
const RegisterContainer = React.lazy(() => import('components/user/RegisterContainer'));
const EventRegistrationContainer = React.lazy(() => import("components/program/event_registration/EventRegistrationContainer"));
const Onboard = React.lazy(() => import("components/user/Onboard"));
const EventProducers = React.lazy(() => import('components/producer/EventProducers'));
const CreateStripeProducerAccount = React.lazy(() => import('components/producer/producerStripeAccount/CreateStripeProducerAccount'));
const ShareLogin = React.lazy(() => import('components/user/ShareLogin'));
const AccessCodeLogin = React.lazy(() => import('components/user/AccessCodeLogin'));
const ScoreCardContainer = React.lazy(() => import('components/judge/rubricScoring/ScoreCardContainer'));
const EventRegistrationPaymentContainer = React.lazy(() => import('components/program/payment/EventRegistrationPaymentContainer'));
const PaymentThankYou = React.lazy(() => import('components/program/payment/PaymentThankYou'));
const ManagePaymentSources = React.lazy(() => import('components/program/payment/ManagePaymentSources'));
const AddPaymentSource = React.lazy(() => import('components/program/payment/AddPaymentSource'));
const SignDocumentContainer = React.lazy(() => import("./components/signature/SignDocumentContainer"));
const PasswordReset = React.lazy(() => import("./components/user/PasswordReset"));
const Fix18 = React.lazy(() => import("./components/super/Fix18"));
const JudgeEventContainer = React.lazy(() => import("./components/judge/JudgeEventContainer"));
const ShiftReport = React.lazy(() => import("./components/judge/reports/ShiftReport"));
const TeamReport = React.lazy(() => import("./components/judge/reports/TeamReport"));
const EventScheduleReport = React.lazy(() => import('components/producer/event/EventScheduleReport'));
const NotFoundPage = React.lazy(() => import('components/NotFoundPage'));
const SampleRubricScoringComponent = React.lazy(() => import('components/judge/rubricScoring/SampleRubricScoring'));
const FormBuilder = React.lazy(() => import('components/judge/rubricScoring/formBuilder/FormBuilder'));
const PublicEventSchedule = React.lazy(() => import('components/producer/event/PublicEventSchedule'));
import Loader from './components/loader'; // The loader component
import SeasonEditor from './components/ScoringAuthority';
export const crisp = Crisp;
crisp.configure('8881d7bd-1b0f-4def-876f-aa81e72aabc4');
crisp.setPosition(ChatboxPosition.Left);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});
const AppWrapper = () => {
  const location = useLocation();
  const isInitialLoad = React.useRef(true);
  useEffect(() => {
    if (isInitialLoad.current) {
      const crispClosed = localStorage.getItem('crispClosed') === 'true';
      if (!crispClosed && !location.pathname.toLowerCase().includes('/documentsign')) {
        crisp.chat.open();
      }
      if (location.pathname.toLowerCase().includes('/documentsign')) {
        crisp.setPosition(ChatboxPosition.Right);
      }
      isInitialLoad.current = false;
    }
  }, [location]);
  useEffect(() => {
    const handleCrispClose = () => {
      localStorage.setItem('crispClosed', 'true');
    };
    const handleCrispOpen = () => {
      localStorage.removeItem('crispClosed');
    };
    crisp.chat.onChatClosed(handleCrispClose);
    crisp.chat.onChatOpened(handleCrispOpen);
  }, []);
  return <App />;
};
ReactDOM.render(<Provider store={store}>
    <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <LoadStripe>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router>
          <AppWrapper />
          <div className="App">
          <Loader />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                /* Super User routes */
                <Route path="/super/fix18" exact component={Fix18} />

                /* Regular routes */
                <Route path="/" exact component={Splash} />
                <Route path="/events/:eventId/schedule" component={PublicEventSchedule} />
                <Route path="/Login" exact component={Login} />
                <Route path="/Register" exact component={RegisterContainer} />
                <Route path="/Account" exact component={Account} />
                <Route path="/ManagePaymentMethods" exact component={ManagePaymentSources} />
                <Route path="/AddPaymentMethod" exact component={AddPaymentSource} />
                <Route path="/ShareLogin" exact component={ShareLogin} />
                <Route path="/AccessCodeLogin" exact component={AccessCodeLogin} />
                <Route path="/PasswordReset" exact component={PasswordReset} />
                <Route path="/PasswordReset/:code" exact component={PasswordReset} />
                <Route path="/ScheduleReport/Event/:eventId" exact component={EventScheduleReport} />
                <Route path="/PublicScoreSheet/Event/:eventId" exact render={props => <PublicScoreSheet routeProps={props} />} />
                
                <Route path="/Location/score_sheet/event/:eventId/team/:teamId" exact render={props => <ScoreSheet routeProps={props} showFilters={false} enableRouting={true} />} />

                <Route path="/Location/:locationId/Team/score_sheet/event/:eventId/team/:teamId" exact render={props => <ScoreSheet routeProps={props} showFilters={false} enableRouting={true} />} />

                <Route path="/JudgeEvent/:eventId" exact component={JudgeEventContainer} />

                <Route path="/AddProgram" exact component={AddEditProgramContainer} />
                <Route path="/EditProgram/:programId" exact component={AddEditProgramContainer} />
                <Route path="/Program/:programId" exact component={ProgramContainer} />

                <Route path="/ScoreCard" exact component={ScoreCardContainer} />

                <Route path="/Program/:programId/AddLocation" exact component={AddEditLocationContainer} />
                <Route path="/EditLocation/:locationId" exact component={AddEditLocationContainer} />
                <Route path="/Location/:locationId" exact component={LocationContainer} />

                <Route path="/Location/:locationId/AddTeam" exact component={AddEditTeamContainer} />
                <Route path="/Location/:locationId/EditTeam/:teamId" exact component={AddEditTeamContainer} />
                <Route path="/Location/:locationId/Team/:teamId" exact component={TeamContainer} />

                <Route path="/Location/:locationId/AddAthlete" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/EditAthlete/:athleteId" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/Team/:teamId/AddAthlete" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/Team/:teamId/EditAthlete/:athleteId" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/Athlete/:athleteId" exact component={Athlete} />
                <Route path="/Location/:locationId/Team/:teamId/Athlete/:athleteId" exact component={Athlete} />
                <Route path="/Location/:locationId/Team/:teamId/AddTeamAthletes" exact component={AddAthleteList} />

                <Route path="/Location/:locationId/Athlete/:athleteId/Guardian/:guardianId" exact component={Guardian} />
                <Route path="/Location/:locationId/Team/:teamId/Athlete/:athleteId/Guardian/:guardianId" exact component={Guardian} />
                <Route path="/Location/:locationId/Athlete/:athleteId/AddGuardian" exact component={AddEditGuardian} />
                <Route path="/Location/:locationId/Team/:teamId/athlete/:athleteId/AddGuardian" exact component={AddEditGuardian} />
                <Route path="/Location/:locationId/Athlete/:athleteId/EditGuardian/:guardianId" exact component={AddEditGuardian} />
                <Route path="/Location/:locationId/Team/:teamId/Athlete/:athleteId/EditGuardian/:guardianId" exact component={AddEditGuardian} />

                <Route path="/Location/:locationId/AddCoach" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/EditCoach/:coachId" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/Team/:teamId/AddCoach" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/Team/:teamId/EditCoach/:coachId" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/Coach/:coachId" exact component={CoachContainer} />
                <Route path="/Location/:locationId/Team/:teamId/coach/:coachId" exact component={CoachContainer} />

                <Route path="/Location/:locationId/AddPersonnel" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/EditPersonnel/:personnelId" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/Team/:teamId/AddPersonnel" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/Team/:teamId/EditPersonnel/:personnelId" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/Personnel/:personnelId" exact component={Personnel} />
                <Route path="/Location/:locationId/Team/:teamId/Personnel/:personnelId" exact component={Personnel} />

                <Route path="/AddProducer" exact component={AddEditProducerContainer} />
                <Route path="/EditProducer/:producerId" exact component={AddEditProducerContainer} />
                <Route path="/Producer/:producerId" exact component={ProducerContainer} />
                <Route path="/Producer/:producerId/CreateMerchantAccount" exact component={CreateStripeProducerAccount} />

                <Route path="/Producer/:producerId/AddBrand" exact component={AddEditBrand} />
                <Route path="/EditBrand/:brandId" exact component={AddEditBrand} />
                <Route path="/Brand/:brandId" exact component={BrandContainer} />

                <Route path="/Brand/:brandId/AddEvent" exact component={AddEditEvent} />
                <Route path="/Brand/:brandId/EditEvent/:eventId" exact component={AddEditEvent} />
                <Route path="/Brand/:brandId/Event/:eventId" exact component={EventContainer} />

                <Route path="/Brand/:brandId/AddJudge" exact component={AddEditJudge} />
                <Route path="/Brand/:brandId/EditJudge/:judgeId" exact component={AddEditJudge} />
                <Route path="/Producer/:producerId/AddJudge" exact component={AddEditJudge} />
                <Route path="/Producer/:producerId/EditJudge/:judgeId" exact component={AddEditJudge} />

                <Route path="/Producer/:producerId/Judge/:judgeId" exact component={Judge} />
                <Route path="/Brand/:brandId/Judge/:judgeId" exact component={Judge} />

                <Route path="/AddUser/:objectType/:objectId/:superObjectId?/:superDuperObjectId?" exact component={AddEditUser} />
                <Route path="/EditUser/:objectType/:objectId/:superObjectId?/:superDuperObjectId?/:userId" exact component={AddEditUser} />

                <Route path="/Register/:locationId/Team/:teamId/:eventId" exact component={EventRegistrationContainer} />
                <Route path="/Registration/:locationId/Team/:teamId/:eventId/:eventTeamId" exact component={EventRegistrationContainer} />
                <Route path="/Program/:programId/RegistrationPayment" exact component={EventRegistrationPaymentContainer} />
                <Route path="/PaymentThankYou" exact component={PaymentThankYou} />

                <Route path="/USASFUpdate" exact component={ScrapeUSASF} />
                <Route path="/USASFUpdate/:programId" exact component={ScrapeUSASF} />
                <Route path="/OnBoard" exact component={Onboard} />

                <Route path="/eventproducers" exact component={EventProducers} />
                <Route path="/connect/:connectUrl" exact component={EventProducers} />

                <Route path="/documentsign/:signCode" exact component={SignDocumentContainer} />

                <Route path="/shiftReport/:shiftId/:divisionId?" exact component={ShiftReport} />
                <Route path="/teamReport/:eventDivisionShiftId" exact component={TeamReport} />
                <Route path="/RubricScoring" exact component={SampleRubricScoringComponent} />
                <Route path="/Producer/:producerId/ScoreSheetBuilder/:rubricId?" exact component={FormBuilder} />
                <Route path="/ScoringAuthority" exact component={SeasonEditor} />
                <Route path="*" exact component={NotFoundPage} />

              </Switch>
              </Suspense>
            </MuiPickersUtilsProvider>
          </div>
        </Router>
      </LoadStripe>
    </ThemeProvider>
    </QueryClientProvider>
  </Provider>, document.querySelector('#root'));
import React, { ReactElement, useMemo } from 'react';
import { AppBar, Grid } from '@material-ui/core';
import { useStyles } from "lib/theme";
import { ProgramPermission, LocationPermission, ProducerPermission, BrandPermission, ProgramPermissions, ProducerPermissions, JudgeEventPermission, JudgeEventPermissions } from "store/user/userReducer";
import HeaderAutoComplete from "./HeaderAutoComplete";
import HeaderBreadcrumbs from "./HeaderBreadcrumbs";
import HeaderMenu from "./HeaderMenu";
import HeaderLogo from "./HeaderLogo";
import { BothSides } from "store/user/userActions";
import { Athlete, Coach, Event, Personnel, Team } from "store/themis_common_pb";
interface HeaderProps {
  programs: ProgramPermissions;
  producers: ProducerPermissions;
  judgeEvents: JudgeEventPermissions;
  isSuperUser: boolean;
  bothSides: BothSides;
  stateEvent: Event.AsObject | undefined;
  stateTeam: Team.AsObject | undefined;
  stateAthlete: Athlete.AsObject | undefined;
  stateCoach: Coach.AsObject | undefined;
  statePersonnel: Personnel.AsObject | undefined;
  userState: string;
  setUserState: Function;
  username: string;
  loggedIn: boolean;
  tryLogin: boolean;
  currentBrand: BrandPermission | undefined;
  currentLocation: LocationPermission | undefined;
  currentProducer: ProducerPermission | undefined;
  setCurrentProducer: Function;
  currentProgram: ProgramPermission | undefined;
  setCurrentProgram: Function;
  currentEventRegistrationLogo: string | undefined;
  currentJudgeEvent: JudgeEventPermission | undefined;
  setCurrentJudgeEvent: Function;
}
const Header: React.FC<HeaderProps> = ({
  programs,
  producers,
  judgeEvents,
  isSuperUser,
  bothSides,
  username,
  loggedIn,
  tryLogin,
  stateTeam,
  stateAthlete,
  stateEvent,
  stateCoach,
  statePersonnel,
  userState,
  setUserState,
  currentBrand,
  currentLocation,
  currentProducer,
  setCurrentProducer,
  currentProgram,
  setCurrentProgram,
  currentEventRegistrationLogo,
  currentJudgeEvent,
  setCurrentJudgeEvent
}): ReactElement => {
  const classes = useStyles();
  const currentFocus = useMemo(() => {
    // This sets the correct permissions in focus for generating the header autocomplete
    if (userState === 'program') {
      return currentProgram;
    } else if (userState === 'producer') {
      return currentProducer;
    } else if (userState === 'judge') {
      return {
        id: currentJudgeEvent?.id,
        name: `${currentJudgeEvent?.brandName || ''} - ${currentJudgeEvent?.name || ''}`,
        eventId: currentJudgeEvent?.id,
        eventName: currentJudgeEvent?.name || ''
        // Add any other relevant properties needed for focus
      };
    }
  }, [userState, currentProducer, currentProgram, currentJudgeEvent]);
  return <AppBar color="transparent" position="sticky" elevation={0} className={classes.headerRoot}>
      <Grid container>
        <HeaderLogo userState={userState} currentFocus={currentFocus} currentBrand={currentBrand} currentEventRegistrationLogo={currentEventRegistrationLogo} judgeEvents={judgeEvents} />
        <Grid item container className={classes.menu} md={8}>
          <HeaderAutoComplete programs={programs} producers={producers} userState={userState} isSuperUser={isSuperUser} bothSides={bothSides} judgeEvents={judgeEvents} />
          <Grid item container xs={12}>
            <HeaderBreadcrumbs currentProducer={currentProducer} currentBrand={currentBrand} currentProgram={currentProgram} currentLocation={currentLocation} stateEvent={stateEvent} stateTeam={stateTeam} stateAthlete={stateAthlete} stateCoach={stateCoach} statePersonnel={statePersonnel} userState={userState} producers={producers} programs={programs} judgeEvents={judgeEvents} />
          </Grid>
        </Grid>
        <Grid item container md={2}>
          <HeaderMenu bothSides={bothSides} username={username || ""} setCurrentProducer={setCurrentProducer} setCurrentProgram={setCurrentProgram} loggedIn={loggedIn} tryLogin={tryLogin} userState={userState} setUserState={setUserState} />
        </Grid>
      </Grid>
    </AppBar>;
};
export default Header;
import React, { FunctionComponent } from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TransitionProps } from '@material-ui/core/transitions';
interface NotificationSnackbarProps {
  isOpen: boolean;
  onClose: (event?: React.SyntheticEvent, reason?: string) => void;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
  autoHideDuration?: number | null;
  position?: number;
  disableWindowBlur?: boolean;
}
function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}
const NotificationSnackbar: FunctionComponent<NotificationSnackbarProps> = ({
  isOpen,
  onClose,
  message,
  severity,
  autoHideDuration = 3000,
  position = 0,
  disableWindowBlur = true
}) => {
  return <Snackbar open={isOpen} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left'
  }} style={{
    bottom: `${20 + position * 60}px`
  }} disableWindowBlur={disableWindowBlur} ClickAwayListenerProps={{
    mouseEvent: false,
    touchEvent: false
  }}>
      <Alert onClose={onClose} severity={severity} elevation={6} variant="filled" tabIndex={-1}>
        {message}
      </Alert>
    </Snackbar>;
};
export default NotificationSnackbar;